import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, FormControl, InputGroup, Dropdown, DropdownButton } from "react-bootstrap";
import Slider from "react-slick";
import * as Icon from 'react-bootstrap-icons';
import logo from '../assets/Vault-X-LOGO.png'
import coming_soon_img from "../assets/Cooking.png"
import network_icon from "../assets/Network.png"
import security_icon from "../assets/Vector.png"
import './home.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const HomePage: React.FC = () => {
    const isMobile = useIsMobile();



    const open_link = (url: any) => {
        // Check if url is a string to prevent potential errors
        if (typeof url === 'string') {
            // Create an 'a' element with the desired url and use it to open the link
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer'; // This is for security reasons

            // Append to the document, click it, and then remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    return (
        <div className="container-fluid">
            <section className="first-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row className='first-section-row'>
                        <Col xs={12} className='text-center'>
                            <div className='logo'>
                                <img src={logo} />
                            </div>
                            <div className='c-s-text '>
                                <img src={coming_soon_img} />
                            </div>
                            <div className='f-s-text-2'>
                                Vault-X 2.0: The Evolution of the Trust Protocol
                            </div>
                            <div className='f-s-card mt-5'>
                                <div className='f-s-card-heading'>
                                    Introducing a New Era of Trust for the Trustless Economy on Solana
                                </div>
                                <div className='f-s-card-text'>
                                    We're dedicated to empowering developers and businesses,
                                    enabling them to harness the Vault-X API Gateway for building robust applications centered around escrow services.
                                </div>
                            </div>
                            <div className='w-p-btn mt-5' onClick={() => open_link('https://thevaultxdao.gitbook.io/the-trust-protocol')}>
                                Whitepaper 2.0 <Icon.ArrowRight size={25} fontWeight={700} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='second-section-row'>
                        <Col xs={12} className='text-center mb-3'>
                            <div className='h-text pb-5'>
                                What's Coming
                            </div>
                        </Col>
                        <Col md={6} className={isMobile ? 'mb-5 text-center' : 'text-center'}>
                            <div className='w-c-card'>
                                <div className='w-c-icon'>
                                    <img src={security_icon} />
                                </div>
                                <div className='w-c-text'>
                                    Seamlessly integrate escrow services into your applications, redefining security and trust in digital transactions.
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='text-center'>
                            <div className='w-c-card'>
                                <div className='w-c-icon'>
                                    <img src={network_icon} />
                                </div>
                                <div className='w-c-text'>
                                    The first resolution Protocol building on Solana to safeguard disputes in Escrow.
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='third-section-row'>
                        <Col md={12} className='text-center mb-4'>
                            <div className='h-text pb-5'>
                                Stay Connected and Informed
                            </div>
                        </Col>
                        <Col md={4} className={isMobile ? 'mb-5 text-center' : 'text-center'}>
                            <div className='t-r-card'>
                                <div className='t-r-card-heading'>
                                    Apply for Trust Protocol Access
                                </div>
                                <div className='t-r-card-button' onClick={() => open_link('https://sqhq4v3jtdk.typeform.com/to/LCTWoESA')}>
                                    Apply Now <Icon.ArrowRight size={25} fontWeight={700} />
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className={isMobile ? 'mb-5 text-center' : 'text-center'}>
                            <div className='t-r-card'>
                                <div className='t-r-card-heading'>
                                    Visit Our Staking <br /> Site
                                </div>
                                <div className='t-r-card-button' onClick={() => open_link('https://stake.thevault-x.com')}>
                                    Start Staking <Icon.ArrowRight size={25} fontWeight={700} />
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className='text-center'>
                            <div className='t-r-card'>
                                <div className='t-r-card-heading'>
                                    Access Existing Escrow <br />Dapp
                                </div>
                                <div className='t-r-card-button' onClick={() => open_link('https://app.thevault-x.com')}>
                                    Access Escrow Dapp <Icon.ArrowRight size={25} fontWeight={700} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='fourth-section-row'>
                        <Col md={12} className='text-center'>
                            <div className='h-text pb-5'>
                                Site Revamp Underway: Stay Tuned
                            </div>
                            <div className='f-s-card'>
                                <div className='f-s-card-heading'>
                                    We're in the midst of creating something truly special. For real-time updates and to be part of our evolving journey:
                                </div>
                                <div className='social-btn-container justify-content-center mt-5'>
                                    <div className={isMobile ? 'social-btn mb-3' : 'social-btn me-2'} onClick={() => open_link('https://twitter.com/TheVaultDAO')}>
                                        <Icon.TwitterX size={20} className='me-2' /> Follow us on X
                                    </div>
                                    <div className={isMobile ? 'social-btn' : 'social-btn ms-2'} onClick={() => open_link('https://discord.gg/Ddk7dJax')}>
                                        <Icon.Discord size={20} className='me-2' />  Join our Discord Community
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className='fifth-section-row'>
                        <Col md={12} className='text-center'>
                            <div className='h2-text'>
                                Exciting developments are on the horizon.
                            </div>
                            <div className='f-s-card-heading mt-5'>
                                Thank you for your patience and support as we build towards Vault-X 2.0.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>




        </div>
    );
}

export default HomePage;
